import {
  ScheduleComponent,
  Day,
  Inject,
  ViewsDirective,
  ViewDirective,
} from "@syncfusion/ej2-react-schedule";
import "./index.scss";

export const Scheduler = (props) => {
  function handleSelectDate(args) {
    props.setSelectedDate && props.setSelectedDate(args);
  }

  const eventSettings = { dataSource: props.data, fields: props.fields };
  return (
    <ScheduleComponent
      width={props.width || "100%"}
      height={props.height}
      eventSettings={eventSettings}
      popupOpen={(args) => (args.cancel = true)}
      selectedDate={props.selectedDate}
      created={() => {
        const dateSelector = document.querySelectorAll(
          ".e-schedule-toolbar .e-date-range"
        )[0];

        dateSelector.click();

        var calendar =
          document.querySelectorAll(".e-header-calendar")[0].ej2_instances[0];

        calendar.change = function (args) {
          handleSelectDate(args);
        };

        dateSelector.click();
        dateSelector.children[0]?.blur();
      }}
    >
      <ViewsDirective>
        <ViewDirective option="Day" />
      </ViewsDirective>
      <Inject services={[Day]} />
    </ScheduleComponent>
  );
};
