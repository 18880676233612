import { Link } from "react-router-dom";

import { renderCustomColumnDefs } from "../../../shared/Table/table.utils";
import { formatDate, parseFixed, renderAmount } from "../../../utils";
import { loadTypeOptions } from "../../Jobs/select.config";
import CustomDriverTableEdit from "./CustomDriverTableEdit";

// Config for table and form
const tableConfig = [
  {
    Header: "#",
    accessor: "",
    width: 10,
    Cell: (row) => {
      return row.row.id * 1 + 1;
    },
  },
  // {
  //   id: "appointment",
  //   header: "Drop Date",
  //   cell: ({ getValue }) => {
  //     const value = getValue();
  //     if (!value) {
  //       return null;
  //     }
  //     return formatDate(value.date);
  //   },
  //   width: 120,
  // },
  {
    header: "Job Load #",
    accessorFn: (row) => row.jobId?._id,
    minWidth: 120,
    cell: ({ row: { original }, getValue }) => {
      const value = getValue();
      return value?.loadNumber || original.jobId?.loadNumber;
    },
  },
  // {
  //   id: "jobId",
  //   header: "Pickup Date",
  //   cell: ({ getValue }) => {
  //     const value = getValue();
  //     if (!value) {
  //       return null;
  //     }
  //     return formatDate(value.transactionDate);
  //   },
  //   width: 120,
  // },
  {
    id: "appointment",
    header: "Date",
    width: 70,
    cell: ({ getValue }) => {
      const value = getValue();
      if (!value) {
        return null;
      }
      return formatDate(value.date, null, true);
    },
  },
  { id: "orderNumber", minWidth: 120, cell: ({ getValue }) => getValue() },
  // { id: "driver", minWidth: 100, cell: ({ getValue }) => getValue()?.fullName },
  { id: "loadType", width: 120, cell: ({ getValue }) => getValue() },
  {
    id: "waitTime",
    minWidth: 100,
    header: "Standby Time",
    cell: ({ getValue }) => {
      const value = getValue();
      if (!value) {
        return null;
      }
      return `${value?.total} hrs`;
    },
  },
  {
    header: "Commission Type",
    id: "payoutType",
    cell: ({ getValue }) => getValue(),
  },
  {
    header: "Supplemental",
    id: "payoutSupplemental",
    editable: {
      type: "number",
      // options: payoutTypeOptions,
    },
    cell: ({ getValue }) => renderAmount(getValue()),
  },
  {
    header: "Rate",
    id: "payoutRate",
    cell: ({ getValue }) => renderAmount(getValue()),
  },
  {
    header: "Mileage",
    id: "route",
    // editable: { type: "number" },
    cell: ({ getValue, row }) => {
      const value = getValue();
      if (!value) {
        return null;
      }
      if (row.original.useCustomManifestMileage) {
        return `${parseFixed(row.original.customManifestMileage).toLocaleString(
          "en-US"
        )} mi`;
      }
      return `${parseFixed(
        value.defaultMileage || value.mileage
      ).toLocaleString("en-US")} mi`;
    },
  },
  {
    header: "Total",
    id: "payoutTotal",
    cell: ({ getValue }) => renderAmount(getValue()),
  },
];

export const COLUMN_DEFINITIONS = renderCustomColumnDefs(tableConfig);
