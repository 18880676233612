import { isObject } from "../../../utils";
import { getSearchParams } from "../../../utils/request";
import { api } from "../../api";

const dispatchApi = api.injectEndpoints({
  tagTypes: ["Dispatch"],
  endpoints: (builder) => ({
    getDispatchList: builder.query({
      query: ({ page = 1, limit = 20, ...rest }) => {
        const query = new URLSearchParams({
          page,
          limit,
          ...rest,
        });
        return `dispatch?${query}`;
      },
      transformResponse: (response) => response.items,
      providesTags: (data) =>
        data?.items
          ? [
              ...data.items.map(({ _id }) => ({ type: "Dispatch", id: _id })),
              { type: "Dispatch", id: "LIST" },
            ]
          : [{ type: "Dispatch", id: "LIST" }],
    }),
  }),
  overrideExisting: false,
});

export const { useGetDispatchListQuery } = dispatchApi;
