import { Avatar, Badge, Button, Modal } from "../../../shared";
import classNames from "classnames";
import { camelCaseToSpaces, renderAmount } from "../../../utils";
import { useEffect, useState } from "react";
import { useDeleteLegsMutation } from "../../../redux/services/legs/legsApi";
import { toast } from "react-toastify";
import { isAuthorized } from "../../../utils/authority";
import { ChevronRightIcon } from "@heroicons/react/24/outline";

const keys = {
  // "accessories",
  loadType: {},
  weight: { render: (e) => e + " lbs" },
  waitTime: { render: (e) => e?.total + " hr" },
  route: {
    title: "Mileage",
    render: (e) => {
      return e?.mileage + " mi";
    },
  },
  baseCharge: { hideJobFlat: true, render: renderAmount, amount: true },
  dropCharge: {
    hideLegFlat: true,
    hideJobFlat: true,
    render: renderAmount,
    amount: true,
  },
  otherCharges: { render: renderAmount, amount: true },
  fuelSurcharge: {
    hideLegFlat: true,
    hideJobFlat: true,
    render: renderAmount,
    amount: true,
  },
  // totalCharges: { render: renderAmount },
};

const ExpandedLeg = ({ row, table, job, ...props }) => {
  const data = row.original;
  const [deleteLegVisible, setDeleteLegVisible] = useState(false);
  const [expanded, setExpanded] = useState(false);

  const [deleteLegs, { isLoading: isDeleting }] = useDeleteLegsMutation();

  function handleCancelModal() {
    setDeleteLegVisible(false);
    // setLoading(false);
  }

  async function handleDelete() {
    const res = await deleteLegs({
      data: [row.original?._id],
      jobId: job._id,
    }).unwrap();

    toast(res.message, {
      type: res.status,
      toastId: "deleted-leg-" + res.data.deletedIds[0],
    });
  }

  const modalFooter = (
    <>
      <Button onClick={handleCancelModal} type="alternative">
        Cancel
      </Button>
      <Button
        loading={props.loading || isDeleting}
        disabled={props.loading || isDeleting}
        onClick={() => {
          handleDelete();
          handleCancelModal();
        }}
        type="primary"
      >
        Submit
      </Button>
    </>
  );

  return (
    <>
      <td
        colSpan={row.getVisibleCells().length}
        className="border-b bg-gray-50 p-0"
      >
        <span>
          <span className="">
            <span className="flex flex-col">
              <p className="grid grid-cols-4 lg:grid-cols-6 xl:grid-cols-8 gap-3 m-5">
                {Object.keys(data)
                  .filter((key) => {
                    if (
                      (data.useLegFlatRate && keys[key]?.hideLegFlat) ||
                      (job.useFlatRate && keys[key]?.hideJobFlat) ||
                      (!isAuthorized("owner,admin") && keys[key]?.amount)
                    ) {
                      return false;
                    }
                    return Object.keys(keys).includes(key);
                  })
                  .map((key) => {
                    let item = data[key];
                    if (key === "accessories") {
                      item = item.length;
                    }
                    if (keys[key].render) {
                      item = keys[key].render(item);
                    }

                    let title = camelCaseToSpaces(key);
                    if (keys[key].title) {
                      title = keys[key].title;
                    }
                    return (
                      <ChargeView
                        loading={props.loading}
                        title={title}
                        value={item}
                      />
                    );
                    // return <p>{`${key}: ${JSON.stringify(item)}`}</p>;
                  })}
              </p>
              {data.metadata && (
                <p className="mx-5">
                  <span className="flex items-center text-gray-500 cursor-pointer hover:text-gray-600">
                    <ChevronRightIcon className="w-4" /> Metadata
                  </span>
                  {expanded && (
                    <span>
                      {Object.keys(data.metadata).map((key) => (
                        <span>
                          {key}: {data.metadata[key]}
                        </span>
                      ))}
                    </span>
                  )}
                </p>
              )}
              <p className="flex justify-between items-end">
                <p className="flex ml-5 pb-3">
                  {data.driver && (
                    <>
                      <Avatar size="sm" src={data.driver?.avatar} />
                      <ChargeView
                        title="Driver"
                        value={data.driver?.fullName}
                      />
                      {isAuthorized("owner,admin") && data.manifestId && (
                        <div>
                          <Badge
                            label={data.manifestId}
                            link={`/manifests/${data.manifestId}`}
                          />
                        </div>
                      )}
                    </>
                  )}
                </p>
                <p>
                  {isAuthorized("owner,admin") && (
                    <p className="flex justify-end">
                      <LegActionsButton
                        onClick={() =>
                          props.editLeg(row.original, table.options.meta.job)
                        }
                        title="Edit"
                        className="hover:bg-blue-100 rounded-tl-lg"
                      />
                      <LegActionsButton
                        onClick={() => setDeleteLegVisible(true)}
                        title="Delete"
                        className="hover:bg-red-100"
                      />
                      {/* <LegActionsButton
                      onClick={() => ""}
                      title="Add Equipment"
                      className="hover:bg-gray-100"
                    />
                    <LegActionsButton
                      onClick={() => props.addAccessory(row.original)}
                      title="Accessories"
                      className="hover:bg-gray-100"
                    />
                    <LegActionsButton
                      onClick={() => ""}
                      title="Mark Complete"
                      className="hover:bg-gray-100"
                    /> */}
                    </p>
                  )}
                </p>
              </p>
            </span>
          </span>
        </span>
      </td>
      <Modal
        visible={deleteLegVisible}
        setVisible={setDeleteLegVisible}
        onClose={() => setDeleteLegVisible(false)}
        title="Delete Leg"
        footer={modalFooter}
        content="Are you sure you want to delete this leg?"
        size="md"
        footerPosition="right"
      />
    </>
  );
};

const ChargeView = ({ title, value, loading }) => {
  return loading ? (
    <div role="status" className="max-w-sm animate-pulse">
      <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mb-4"></div>
      <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[360px] mb-2.5"></div>
      <span className="sr-only">Loading...</span>
    </div>
  ) : (
    <span className="flex flex-col ml-2">
      <span className="text-sm font-semibold text-gray-500">{title}</span>
      <span>{value}</span>
    </span>
  );
};

const LegActionsButton = ({ title, onClick, className }) => {
  const classes = classNames(
    "px-8 py-2 border border-b-0 border-r-0",
    "cursor-pointer",
    className
  );
  return (
    <p className={classes} onClick={onClick}>
      {title}
    </p>
  );
};

export default ExpandedLeg;
