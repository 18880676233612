import classNames from "classnames";
import React, { useState } from "react";
import { useEffect } from "react";

export const TextArea = (props) => {
  const [value, setValue] = useState("");

  useEffect(() => {
    props.value && setValue(props.value);
  }, [props.value]);

  function handleChange(e) {
    const { target } = e;

    props.onChange && props.onChange(target);
    setValue(target.value);
  }

  const textareaClass =
    props.className ||
    classNames(
      props.textAreaClass,
      "block p-2.5 w-full text-sm rounded-lg text-gray-900",
      "border border-gray-300 focus:ring-primary-500 focus:outline-none focus-visible:ring-1 focus:ring-1 focus-visible:ring-primary-500 focus:border-primary-500"
    );
  return (
    <>
      {props.label && (
        <label
          htmlFor={props.id}
          className="block mb-2 text-sm font-medium text-gray-900"
        >
          {props.label}
        </label>
      )}
      <textarea
        value={value}
        onChange={handleChange}
        id={props.id}
        rows={props.rows || "4"}
        className={textareaClass}
        placeholder={props.placeholder}
      />
    </>
  );
};

export default TextArea;
