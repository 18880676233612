import { XMarkIcon } from "@heroicons/react/24/outline";
import classNames from "classnames";
import React, { useState } from "react";
import { useEffect } from "react";
import Input from "../Input";

export const TimeInput = (props) => {
  return (
    <Input
      {...props}
      type="time"
      id={props.id}
      value={props.value}
      placeholder={props.placeholder}
    />
  );
};

export default TimeInput;
