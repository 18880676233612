import React, { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import DashboardLayout from "./layouts/Dashboard";
import LoginLayout from "./layouts/Login";
// import Account from "./pages/Account";
import AccountSettings from "./pages/Account/Settings";
import AccountSettingsPage from "./pages/Account/Settings/Settings";
import AccountInfoPage from "./pages/Account/Settings/UserInfo";
import Clients from "./pages/Clients";
import ClientOnboarding from "./pages/Clients/Onboard";
import ClientShow from "./pages/Clients/Show";
import ClientEdit from "./pages/Clients/Edit";
import Dashboard from "./pages/Dashboard";
import Drivers from "./pages/Drivers";
import DriverShow from "./pages/Drivers/Show";
import Help from "./pages/Help";
import Jobs from "./pages/Jobs";
// import JobsCreate from "./pages/Jobs/Create";
import JobsShow from "./pages/Jobs/Show";
import { Users, UserShow } from "./pages/Settings/Users";
import UserEdit from "./pages/Users/Edit";
import UserCreate from "./pages/Users/Create";
// import UserShow from "./pages/Users/Show";
import Login from "./pages/Login";
import NoMatch from "./pages/NoMatch";
import SuperAdmin from "./pages/SuperAdmin";
// import CustomerOnboarding from "./pages/SuperAdmin/Customer/Onboarding";
// import Components from "./pages/Components";
import ForgotPassword from "./pages/ForgotPassword";
import WebLayout from "./layouts/WebLayout";
import ReportBug from "./pages/ReportBug";
import Invoices from "./pages/Invoices";
import InvoiceCreate from "./pages/Invoices/Create";
import InvoiceShow from "./pages/Invoices/Show";
import InvoiceEdit from "./pages/Invoices/Edit";
import Payouts from "./pages/Payouts";
import PayoutCreate from "./pages/Payouts/Create";
import PayoutsShow from "./pages/Payouts/Show";
import Customers from "./pages/SuperAdmin/Customers";
import Feedback from "./pages/Feedback";
import PayoutEdit from "./pages/Payouts/Edit";
import UsersAdmin from "./pages/SuperAdmin/Users";
import ChangePassword from "./pages/ChangePassword";
import {
  fetchCurrentUser,
  selectCurrentUser,
} from "./redux/reducers/accountSlice";
// import AdminFeedback from "./pages/SuperAdmin/Feedback";
import FeedbackShow from "./pages/Feedback/show old";
import ClientLocationsIndex from "./pages/Locations";
import Colors from "./pages/Colors";
import OrganizationShow from "./pages/Organizations/Show";
import useVersionCheck from "./hooks/useVersionUpdate";
import Settings from "./pages/Settings";
import CalendarScheduler from "./pages/Calendar";
import FeedbackCreate from "./pages/Feedback/Create";
import Manifests from "./pages/Manifests";
import ManifestsShow from "./pages/Manifests/Show";
import ManifestsEdit from "./pages/Manifests/Edit";
import { Onboarding } from "./pages/Onboarding";
import GeneralSettings from "./pages/Settings/GeneralSettings";
import Rates from "./pages/Settings/Rates";
import CustomerShow from "./pages/SuperAdmin/Customers/Show";
import { Billing } from "./pages/Settings/Billing";
import { Features } from "./pages/Settings/Features";
import { Scheduler } from "./shared/Scheduler";
import { useConfig } from "./hooks";
import { registerLicense } from "@syncfusion/ej2-base";
import { Dispatch } from "./pages/Dispatch";

function App() {
  const dispatch = useDispatch();
  const accountStatus = useSelector((state) => state.account.status);
  const currentUser = useSelector(selectCurrentUser);

  useVersionCheck();
  registerLicense(
    "Ngo9BigBOggjHTQxAR8/V1NCaF5cXmZCf1FpRmJGdld5fUVHYVZUTXxaS00DNHVRdkdnWXhccXVQRGBeUk1wWUc="
  );

  useEffect(() => {
    if (accountStatus === "idle") {
      dispatch(fetchCurrentUser());
    }
  }, [accountStatus]);

  return (
    <Routes>
      <Route path="/" element={<DashboardLayout currentUser={currentUser} />}>
        <Route index element={<Dashboard />} />
        <Route path="jobs" element={<Jobs />} />
        {/* <Route path="jobs/create" element={<JobsCreate />} /> */}
        <Route path="jobs/:id" element={<JobsShow />} />
        <Route path="users" element={<Users />} />
        <Route path="users/create" element={<UserCreate />} />
        <Route path="users/:id" element={<UserEdit />} />
        <Route path="users/:id/edit" element={<UserEdit />} />
        {/* <Route path="account" element={<Account currentUser={currentUser} />} /> */}
        {/* <Route path="account/settings" element={<AccountSettings />} /> */}
        <Route path="calendar" element={<CalendarScheduler />} />
        <Route path="dispatch" element={<Dispatch />} />
        <Route path="clients" element={<Clients />} />
        <Route path="clients/create" element={<ClientOnboarding />} />
        <Route path="clients/:id" element={<ClientShow />} />
        <Route
          path="clients/:id/locations"
          element={<ClientLocationsIndex />}
        />
        <Route path="clients/:id/edit" element={<ClientEdit />} />
        <Route path="drivers" element={<Drivers />} />
        <Route path="drivers/:id/" element={<DriverShow />} />
        <Route path="organizations/:id/" element={<OrganizationShow />} />
        <Route path="manifests" element={<Manifests />} />
        {/* <Route path="manifests/create" element={<Payouts />} /> */}
        <Route path="manifests/:id/edit" element={<ManifestsEdit />} />
        <Route path="manifests/:id" element={<ManifestsShow />} />
        <Route path="payouts" element={<Payouts />} />
        <Route path="payouts/create" element={<PayoutCreate />} />
        <Route path="payouts/:id/edit" element={<PayoutEdit />} />
        <Route path="payouts/:id" element={<PayoutsShow />} />
        <Route path="invoices" element={<Invoices />} />
        <Route path="invoices/:id/edit" element={<InvoiceEdit />} />
        <Route path="invoices/:id" element={<InvoiceShow />} />
        <Route path="invoices/create" element={<InvoiceCreate />} />
        <Route path="feedback" element={<Feedback />} />
        <Route path="feedback/create" element={<FeedbackCreate />} />
        <Route path="feedback/:id" element={<FeedbackShow />} />
        <Route path="feedback/show" element={<FeedbackCreate />} />
        <Route path="settings" element={<Settings />}>
          <Route index element={<GeneralSettings />} />
          <Route path="general" element={<GeneralSettings />} />
          <Route path="rates" element={<Rates />} />
          <Route path="users" element={<Users />} />
          <Route path="users/:id" element={<UserShow />} />
          <Route path="billing" element={<Billing />} />
          <Route path="features" element={<Features />} />
        </Route>
        <Route path="account" element={<AccountSettings />}>
          <Route index element={<AccountInfoPage />} />
          <Route path="information" element={<AccountInfoPage />} />
          <Route path="settings" element={<AccountSettingsPage />} />
        </Route>
        {/* <Route path="onboarding" element={<Onboarding />} /> */}
        <Route path="colors" element={<Colors />} />
      </Route>
      <Route path="/" element={<LoginLayout currentUser={currentUser} />}>
        <Route index path="login" element={<Login />} />
        <Route path="forgot/password" element={<ForgotPassword />} />
        <Route path="change/password" element={<ChangePassword />} />
        <Route path="activate" element={<Onboarding />} />
      </Route>
      <Route path="admin" element={<DashboardLayout />}>
        <Route index element={<SuperAdmin />} />
        <Route path="feedback/:id" element={<FeedbackShow />} />
        {/* <Route path="customers/onboarding" element={<CustomerOnboarding />} /> */}
        {/* <Route path="customers/:id" element={<CustomerOnboarding />} /> */}
        <Route path="customers" element={<Customers />} />
        <Route path="customers/:id" element={<CustomerShow />} />
        <Route path="users" element={<UsersAdmin />} />
        <Route path="users/:id" element={<UserEdit />} />
        <Route path="feedback" element={<Feedback />} />
      </Route>
      <Route path="/" element={<WebLayout />}>
        <Route path="help" element={<Help />} />
        <Route path="report/bug" element={<ReportBug />} />
        <Route path="*" element={<NoMatch />} />
      </Route>
    </Routes>
  );
}

export default App;
