import { useEffect, useState } from "react";
import { isObject } from "../utils";

export const useForm = (options) => {
  const [form, setForm] = useState({});
  const [isValid, setIsValid] = useState(false);
  const [errors, setErrors] = useState({});
  const [hasBeenInit, setHasBeenInit] = useState({});

  useEffect(() => {
    if (isFormValid()) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form, isValid]);

  function updateForm(obj, options) {
    if (options) {
      if (options.formArray) {
        let init = {};
        Object.keys(obj.value).forEach((key, i) => {
          if (isObject(obj.value[key])) {
            Object.keys(obj.value[key]).forEach((item, i) => {
              init[item] = true;
            });
          } else {
            init[key] = true;
          }
        });
        setHasBeenInit({ ...hasBeenInit, [obj.id]: true, ...init });
      }
      if (options.valid) {
        setIsValid(true);
      }
    }
    if (!form[obj.id]) {
      setHasBeenInit({ ...hasBeenInit, [obj.id]: true });
    }

    return setForm({ ...form, [obj.id]: obj.value });
  }

  function setFormInit(obj, options) {
    if (options) {
      if (options.setErrors) {
        let init = {};
        Object.keys(obj).forEach((key) => (init[key] = true));
        setHasBeenInit(init);
      }

      if (options.valid) {
        setIsValid(true);
      }

      if (options.reset) {
        return setForm({ ...obj });
      }
    }

    return setForm({ ...form, ...obj });
  }

  function clearForm(id) {
    if (Array.isArray(id)) {
      id.forEach((item) => {
        delete form[item];
      });
      setForm({ ...form });
      return;
    }
    if (id) {
      delete form[id];
      setForm({ ...form });
      return;
    }

    return setForm({});
  }

  function valueIsEmpty(value) {
    if (!value) return true;

    if (value instanceof Object && Object.keys(value).length === 0) {
      return true;
    }

    if (Array.isArray(value) && value.length < 1) {
      return true;
    }

    if (typeof value === "string" && value === "") {
      return true;
    }

    return false;
  }

  function isFormValid() {
    let validations = options?.validations;

    if (validations) {
      let valid = true;
      let newErrors = {};

      Object.keys(validations).forEach((key) => {
        let hasIdBeenInit = hasBeenInit[key];
        const value = form[key];
        const validation = validations[key];

        if (!hasIdBeenInit) {
          valid = false;
          return;
        }

        // Regex
        const pattern = validation?.pattern;
        if (pattern?.value && !RegExp(pattern.value).test(value)) {
          valid = false;
          newErrors[key] = pattern.message;
        }

        // Custom
        const custom = validation?.custom;
        const customIsValid = custom?.isValid(value, {
          validations: validations[key],
          form,
          isFormValid,
          validateEmail,
          valueIsEmpty,
          hasBeenInit,
        });
        if (custom?.isValid) {
          if (isObject(customIsValid) && !customIsValid.isValid) {
            valid = false;
            newErrors = { ...newErrors, ...customIsValid.errors };
          }
        }

        // Email
        if (validation?.validations?.email && !validateEmail(value)) {
          valid = false;
          newErrors[key] = "Must be a valid email";
        }

        // Required
        if (
          (validation?.required ||
            validation?.validations?.required ||
            validation?.required?.value) &&
          valueIsEmpty(value)
        ) {
          valid = false;
          newErrors[key] =
            validation?.required?.message || "This field is required";
        }
      });

      if (!valid) {
        setErrors(newErrors);
        return false;
      }
    }

    setErrors({});
    return true;
  }
  return {
    form,
    updateForm,
    setFormInit,
    isValid,
    errors,
    clearForm,
    isFormValid,
    hasBeenInit,
  };
};

export default useForm;

const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};
