import {
  getCoreRowModel,
  getFacetedMinMaxValues,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
  getExpandedRowModel,
} from "@tanstack/react-table";
import classNames from "classnames";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";
import _ from "lodash";
import Spinner from "../Spinner";
import { renderDefaultVisible } from "./table.utils";
import TBody from "./TBody";
import THead from "./THead";
import TableHeader from "./TableHeader";
import IndeterminateCheckbox from "./IndeterminateCheckbox";
import { rankItem } from "@tanstack/match-sorter-utils";
import { Link } from "react-router-dom";
import { FolderPlusIcon } from "@heroicons/react/24/outline";
import DropArrow from "./DropArrow";
import { isObject } from "../../utils";
import "./index.css";

export const Table = forwardRef((props, ref) => {
  const tableData = useMemo(() => props.data || []);
  const tableCols = useMemo(() => renderColumns(props), [props.columns.length]);
  const [columnVisibility, setColumnVisibility] = useState(
    renderDefaultVisible(props.columns)
  );
  const [globalFilter, setGlobalFilter] = useState("");
  const [columnFilters, setColumnFilters] = useState([]);
  const [rowSelection, setRowSelection] = useState({});
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: props.pageSize || 20,
  });
  const [autoResetPageIndex, skipAutoResetPageIndex] = useSkipper();
  const [expanded, setExpanded] = useState({});

  const fuzzyFilter = (row, columnId, value, addMeta) => {
    if (
      props.globalSearchFilters &&
      !props.globalSearchFilters.includes(columnId)
    ) {
      return false;
    }
    // Rank the item
    const itemRank = rankItem(row.getValue(columnId), value);

    // Store the itemRank info
    addMeta({ itemRank });

    // Return if the item should be filtered in/out
    return itemRank.passed;
  };

  const state = {
    columnFilters,
    globalFilter,
    columnVisibility,
    rowSelection,
    expanded,
    pagination,
  };

  if (isObject(props.pagination)) {
    state.pagination = {
      pageIndex: props.pageIndex,
      pageSize: props.pageSize,
    };
  }

  const table = useReactTable({
    data: tableData,
    columns: tableCols,
    state,
    meta: {
      fullTextSearch: props.fullTextSearch,
      ...props.meta,
    },
    filterFns: {
      fuzzy: fuzzyFilter,
    },
    initialState: {
      pagination: {
        pageSize: props.pageSize || 20,
      },
    },
    manualPagination: props.manualPagination || isObject(props.pagination),
    enableRowSelection: true,
    enableSorting: props.enableSorting,
    enableMultiRowSelection: true,
    columnResizeMode: "onChange",
    // getSubRows: (row) => row.subItem,
    getRowCanExpand: props.getRowCanExpand,
    onPaginationChange: props.onPaginationChange || setPagination,
    onExpandedChange: setExpanded,
    onRowSelectionChange: setRowSelection,
    onColumnVisibilityChange: setColumnVisibility,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    globalFilterFn: fuzzyFilter,
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
  });

  const wrapperClasses = props.className
    ? props.className
    : classNames(
        "flex flex-col rounded-lg border border-t-0 border-b-0 bg-gray-50 shadow shadow-t-0",
        props.wrapperClasses,
        {
          "h-full": props.loading || !props.data || props.data.length === 0,
        }
      );
  const classes = props.tableWrapperClasses
    ? props.tableWrapperClasses
    : classNames(
        props.innerWrapClass,
        "inline-block w-full overflow-x-auto overflow-y-auto h-full",
        "align-middle border-gray-200 rounded-b-lg",
        {
          "border-t": props.title || props.actions,
        }
      );

  const loading = (
    <div
      style={props.tableInnerWrapStyle}
      className="flex items-center justify-center h-full p-3 "
    >
      <Spinner size="md" /> <div className="pl-2">Loading...</div>
    </div>
  );

  const emptyState = props.emptyState ? (
    props.emptyState
  ) : (
    <div className="py-10 h-full ">
      <div className="text-center">
        <FolderPlusIcon className="w-12 h-12 mx-auto text-gray-400 stroke-1" />
        <h3 className="mt-2 font-semibold text-sm">
          No {props.id || props.title || "Data"}
        </h3>
        <p className="text-sm text-gray-500">Get started by adding some data</p>
        {/* <div className="mt-3">{renderButton("md")}</div> */}
      </div>
    </div>
  );

  useEffect(() => {
    // if (table.getState().columnFilters[0]?.id === "fullName") {
    //   if (table.getState().sorting[0]?.id !== "fullName") {
    //     table.setSorting([{ id: "fullName", desc: false }]);
    //   }
    // }
  }, [table.getState().columnFilters[0]?.id]);

  const selectedRows = table
    .getSelectedRowModel()
    .rows.map((item) => item.original);

  useEffect(() => {
    if (props.onSelectedRowsChange && selectedRows) {
      props.onSelectedRowsChange(selectedRows);
    }
  }, [selectedRows.length]);

  useEffect(() => {
    table.setPageSize(props.pageSize || 20);
  }, [props.pageSize]);

  useImperativeHandle(
    ref,
    () => ({
      resetSelectedRows: (bool) => table.toggleAllRowsSelected(bool),
      resetExpandedRows: (bool) => table.toggleAllRowsExpanded(bool),
    }),
    []
  );

  const useStyles = !props.turnOffStyles;

  const TableWrapper = props.useDivs || !useStyles ? "div" : "table";

  return (
    <div className={wrapperClasses}>
      {!props.hideHeader &&
        (props.title || props.action || props.search || props.renderHeader) && (
          <TableHeader
            {...props}
            table={table}
            globalFilter={globalFilter}
            setGlobalFilter={setGlobalFilter}
          />
        )}
      <div style={props.tableOuterWrapStyle}>
        <div style={props.tableInnerWrapStyle} className={classes}>
          {props.loading ? (
            loading
          ) : !props.data || props.data.length === 0 ? (
            emptyState
          ) : (
            <TableWrapper
              className={props.tableClassName || "min-w-full h-full"}
              style={
                props.useTableSizes || !useStyles
                  ? { width: table.getCenterTotalSize() }
                  : props.wrapperStyles
              }
            >
              <THead {...props} useStyles={useStyles} table={table} />
              <TBody
                {...props}
                useStyles={useStyles}
                table={table}
                updateData={props.updateData}
              />
            </TableWrapper>
          )}
        </div>
      </div>
      {(!props.hideFooter &&
        (props.footer ? props.footer : tableData.length > props.pageSize)) ||
      props.showFooter ? (
        <div className="py-3 text-center border-t">
          <Link
            className="text-primary-600 hover:underline"
            to={props.footerLink || `/${props.id}`}
          >
            View All
          </Link>
        </div>
      ) : null}
    </div>
  );
});

export default Table;

export function useSkipper() {
  const shouldSkipRef = React.useRef(true);
  const shouldSkip = shouldSkipRef.current;

  // Wrap a function with this to skip a pagination reset temporarily
  const skip = React.useCallback(() => {
    shouldSkipRef.current = false;
  }, []);

  React.useEffect(() => {
    shouldSkipRef.current = true;
  });

  return [shouldSkip, skip];
}

export function renderColumns(props) {
  const columns = props.columns;
  const firstCol = columns[0];
  if (props.hideCheckbox) {
    const cellRender = firstCol.cell;

    firstCol.cell = (cellProps) => {
      return cellProps.row.getCanExpand() ? (
        <div
          className="flex items-center"
          style={{
            paddingLeft: `${cellProps.row.depth * 2}rem`,
          }}
        >
          <DropArrow {...cellProps} />
          {cellRender(cellProps)}
        </div>
      ) : (
        cellRender(cellProps)
      );
    };

    columns[0] = firstCol;
  } else {
    firstCol.id !== "select" &&
      columns.unshift({
        id: "select",
        header: ({ table }) => (
          <IndeterminateCheckbox
            header={true}
            checked={table.getIsAllRowsSelected()}
            indeterminate={table.getIsSomeRowsSelected()}
            onChange={table.getToggleAllPageRowsSelectedHandler()}
            // onChange={table.getToggleAllRowsSelectedHandler()}
          />
        ),
        cell: ({ row }) => (
          <div className="px-1">
            <IndeterminateCheckbox
              checked={row.getIsSelected()}
              indeterminate={row.getIsSomeSelected()}
              onChange={row.getToggleSelectedHandler()}
            />
          </div>
        ),
      });
  }

  return columns;
}
