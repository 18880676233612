import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../../shared/Button";
import Modal from "../../../shared/Modal";
import { useAuth } from "../../../hooks/useAuth";
import useForm from "../../../hooks/useForm";
import {
  fetchClients,
  selectAllClients,
} from "../../../redux/reducers/clients/clientsSlice";
import request, { renderQueries } from "../../../utils/request";
import InputDropdown from "../../../shared/InputDropdown";
import { useNavigate } from "react-router-dom";
import { AvatarClient } from "../../../components";
import { DatePicker, Input } from "../../../shared";
import { formatDate } from "../../../utils";
import { useAddInvoiceMutation } from "../../../redux/services/invoices/invoicesApi";
import { toast } from "react-toastify";
import { endOfDay, startOfDay } from "date-fns";

const CreateModal = ({ modalOpen, setModalOpen, ...props }) => {
  const { user } = useAuth();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { form, updateForm, clearForm, setFormInit } = useForm();

  const [loading, setLoading] = useState(false);
  const [recentClients, setRecentClients] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  const [addInvoice, { isLoading: addingInvoice }] = useAddInvoiceMutation();

  const clients = useSelector(selectAllClients);
  const clientsStatus = useSelector((state) => state.clients.status);

  useEffect(() => {
    if (!props.client && clientsStatus === "idle") {
      dispatch(fetchClients(user.parentCompany));
    }
  }, [clientsStatus, user, props.client]);

  async function getRecentClients() {
    const res = await request(
      "/clients/recent?" + renderQueries({ parent: user.parentCompany })
    );

    if (res.data.status === "success") {
      setRecentClients(res.data.data);
    }
  }

  // useEffect(() => {
  //   const delayDebounceFn = setTimeout(async () => {
  //     if (searchTerm) {
  //       const res = await request(
  //         "/clients/search?" +
  //           renderQueries({ parent: user.parentCompany, search: searchTerm })
  //       );

  //       const { data } = res.data;
  //     }
  //   }, 1000);

  //   return () => clearTimeout(delayDebounceFn);
  // }, [searchTerm]);

  useEffect(() => {
    if (recentClients.length === 0) {
      getRecentClients();
    }
  }, [recentClients.length]);

  function getQuery() {
    return {
      parent: user.parentCompany,
      client: { _id: form.client._id, slug: form.client.slug },
      invoiceId: null,
      route: true,
      // pickupDate: form.pickupDate,
      startDate: startOfDay(form.startDate).toISOString(),
      endDate: endOfDay(form.endDate).toISOString(),
      legacy: false,
    };
  }

  const handleDateUpdate = () => {
    const query = getQuery();
    getCount(query);
  };

  const getCount = async (query) => {
    query.countOnly = true;
    query.client = query.client._id;
    const res = await request(`/jobs?${renderQueries(query)}`);
    updateForm({ id: "count", value: res.data.count });
  };

  useEffect(() => {
    if (form.startDate && form.endDate && form.client) {
      handleDateUpdate();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.startDate, form.endDate, form.client]);

  useEffect(() => {
    if (props.client && modalOpen) {
      setFormInit({ client: props.client });
    }
  }, [setFormInit]);

  function closeModal() {
    setModalOpen(false);
    handleCancel();
  }

  const footer = (
    <div>
      <Button className="mr-3" onClick={closeModal} type="alternative">
        Cancel
      </Button>
      <Button
        loading={addingInvoice}
        disabled={form.count === 0 || addingInvoice}
        onClick={createInvoice}
        type="primary"
      >
        Submit
      </Button>
    </div>
  );

  async function createInvoice() {
    const postData = {
      ...getQuery(),
      useLegacyCreate: false,
    };

    const res = await addInvoice(postData).unwrap();

    toast(res.message, {
      type: res.status,
      toastId: "add-invoice",
    });

    if (res.status === "success") {
      navigate("/invoices/" + res.data._id);
    }

    // const { data } = await request(
    //   "/invoices?" + renderQueries({ parent: user.parentCompany }),
    //   {
    //     method: "post",
    //     data: postData,
    //   }
    // );

    // if (data.status === "success") {
    //   setLoading(false);
    //   navigate("/invoices/" + data.data._id);
    // }
  }

  function handleSelectClient(client) {
    updateForm({ id: "client", value: client });
  }

  function handleCancel() {
    clearForm("client");
  }

  const createModalContent = (
    <div className="">
      {!form.client ? (
        <div className="space-y-3">
          <div className="col-span-2 md:col-span-1 md:w-1/2  xl:col-span-2 ">
            <InputDropdown
              onChange={(e) => setSearchTerm(e.value)}
              value={searchTerm}
              placeholder="Search clients by name"
              data={clients}
              renderItem={(e) => (
                <AvatarClient
                  onClick={() => handleSelectClient(e.item)}
                  {...e}
                  {...e.item}
                  disableCancel
                />
              )}
            />
          </div>
          <div className="flex items-center justify-between">
            <div>Recent clients</div>
            {/* <div className="flex items-center">
              <PlusIcon className="w-5 h-5 mr-1" />
              Add new customer
            </div> */}
          </div>
          <div>
            {recentClients.map((recent, i) => {
              return (
                <AvatarClient
                  key={i}
                  onClick={() => handleSelectClient(recent)}
                  {...recent}
                />
              );
            })}
          </div>
        </div>
      ) : (
        <>
          <div className="mb-5 ">
            <AvatarClient selected onCancel={handleCancel} {...form.client} />
          </div>
          <div className="mb-5 items-center gap-4 mx-5 grid grid-cols-1 w-1/2 md:w-full md:grid-cols-3">
            <div className="">
              <DatePicker
                id="startDate"
                value={formatDate(form.startDate, null, true)}
                placeholder="Start Date"
                label="Start Date"
                onChange={updateForm}
              />
            </div>
            <div className="">
              <DatePicker
                id="endDate"
                value={formatDate(form.endDate, null, true)}
                placeholder="End Date"
                label="End Date"
                onChange={updateForm}
              />
            </div>
            <div className="">
              <Input
                disabled
                className=" !w-1/2"
                label="Job Count:"
                value={form.count || 0}
              />
            </div>
          </div>
        </>
      )}
    </div>
  );

  const title = <span className="mx-4">Select a client</span>;

  return (
    <Modal
      visible={modalOpen}
      setVisible={setModalOpen}
      onClose={closeModal}
      title={title}
      footer={footer}
      content={createModalContent}
      footerPosition="right"
      contentWrapperclassName="p-3 lg:p-6 space-y-6"
    />
  );
};

export default CreateModal;
