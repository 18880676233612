import classNames from "classnames";
import React from "react";
import Spinner from "../Spinner";

export const Avatar = (props) => {
  const size = props.size;
  const xs = size === "xs";
  const sm = size === "sm";
  const md = size === "md" || !size;
  const lg = size === "lg";

  const sizes = {
    "w-7 h-7": xs,
    "w-10 h-10": sm,
    "w-24 h-24": md,
    "w-28 h-28 md:w-36 md:h-36": lg,
  };

  const classes = classNames(
    props.className,
    sizes,
    "m-0 relative flex justify-center items-center rounded-full bg-gray-500 text-xl text-white"
  );

  const imgClasses = classNames(sizes, "rounded-full w-full");

  return (
    <div className={classes}>
      {props.loading ? (
        <Spinner />
      ) : (
        <img alt="avatar" src={props.src} className={imgClasses} />
      )}
    </div>
  );
};

export default Avatar;
