import React, { useEffect, useState } from "react";
import Modal from "../../../shared/Modal";
import Button from "../../../shared/Button";
import useForm from "../../../hooks/useForm";
import { useAuth } from "../../../hooks/useAuth";
import { useDispatch, useSelector } from "react-redux";
import { addNewUser } from "../../../redux/reducers/users/userSlice";
import { driverAdded } from "../../../redux/reducers/drivers/driversSlice";
import { toast } from "react-toastify";
import {
  fetchOrganizations,
  selectOrganizationsOptions,
} from "../../../redux/reducers/organizations/organizationsSlice";
import { FORM_OPTIONS } from "../driver.table.config";
import DriverCreateForm from "./DriverCreateForm";
import Toggle from "../../../shared/Toggle";

const DriverForm = ({ visible, setVisible, hideOrg, children, ...props }) => {
  const { user } = useAuth();
  const dispatch = useDispatch();
  const [_loading, setLoading] = useState(false);
  const [addExisting, setAddExisting] = useState(false);

  const { form, clearForm, updateForm, errors, setFormInit } =
    useForm(FORM_OPTIONS);

  const [driverOrg, setDriverOrg] = useState(false);

  const orgs = useSelector((state) => state.organizations);
  const orgOptions = useSelector(selectOrganizationsOptions);

  useEffect(() => {
    if (driverOrg && orgs.status === "idle") {
      dispatch(fetchOrganizations({ parent: user.parentCompany }));
    }
  }, [driverOrg, orgs.status]);

  function handleClose() {
    setVisible(false);
    setLoading(false);
    clearForm();
    setDriverOrg(false);
  }

  async function handleSubmit() {
    setLoading(true);

    if (props.toggle && addExisting) {
      setVisible(false);
      setDriverOrg(false);
      setLoading(false);
      return props.onSubmit && props.onSubmit(form, true);
    }

    const res = await dispatch(
      addNewUser({
        data: {
          ...form,
          type: "driver",
          meta: {
            license: form.license,
            hourlyRate: form.hourlyRate,
            mileageRate: form.mileageRate,
            emptyRate: form.emptyRate,
          },
        },
        parent: user.parentCompany,
      })
    ).unwrap();

    if (res.status === "success") {
      props.onSubmit && props.onSubmit(res);
      setVisible(false);
      clearForm();
      setDriverOrg(false);
      setLoading(false);
      dispatch(driverAdded(res));
      toast("Successfully created a new driver", {
        toastId: "newDriverAdded",
        type: "success",
      });
    }
  }

  const modalFooter = (
    <div>
      <Button className="mr-3" onClick={handleClose} type="alternative">
        Cancel
      </Button>
      <Button
        // disabled={loading || !isValid}
        // loading={loading}
        onClick={handleSubmit}
      >
        Submit
      </Button>
    </div>
  );

  const modalProps = {
    form,
    updateForm,
    clearForm,
    setDriverOrg,
    errors,
    driverOrg,
    orgs: orgs.data,
    orgOptions,
    hideOrg,
    user,
  };

  const modalContent = (
    <div className="space-y-3">
      {props.toggle && (
        <Toggle
          label="Create"
          checked={!props.addExisting}
          onChange={() => setAddExisting(!addExisting)}
        />
      )}
      {addExisting ? props.content : <DriverCreateForm {...modalProps} />}
    </div>
  );

  return (
    <>
      {children &&
        React.Children.map(children, (child) => {
          if (React.isValidElement(child)) {
            return React.cloneElement(child, {
              form,
              clearForm,
              updateForm,
              setFormInit,
            });
          }
          return child;
        })}
      <Modal
        visible={visible}
        setVisible={setVisible}
        onClose={handleClose}
        title="Add Driver"
        footer={modalFooter}
        content={modalContent}
        size="lg"
        footerPosition="right"
      />
    </>
  );
};

export default DriverForm;
