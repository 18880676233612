import { Badge, Popover } from "../../../shared";
import { ClockIcon, PhoneIcon } from "@heroicons/react/24/outline";
import { formatPhoneNumber } from "../../../utils";
import { isAuthorized } from "../../../utils/authority";

const LocationTableCell = ({ data }) => {
  if (!data) return "";

  return (
    <div className="flex flex-col">
      <div className="font-bold text-base">{data.name}</div>
      <div>
        {data.address?.city}, {data.address?.state}
      </div>
      <div className="flex space-x-1">
        {isAuthorized("owner,admin") && data.precall && (
          <div>
            <Popover
              className="w-43"
              position="top"
              trigger={<Badge>Precall</Badge>}
              offset={[-12, 0]}
            >
              {data.address?.receivingHours?.open && (
                <div className="flex items-center">
                  <ClockIcon className="w-4 h-4" />
                  <span className="text-gray-900 font-semibold test-base mx-2">
                    {data.address?.receivingHours?.open}-
                    {data.address?.receivingHours?.close}
                  </span>
                </div>
              )}
              {data.phone ? (
                <div className="flex items-center">
                  <PhoneIcon className="w-4 h-4" />
                  <span className="text-gray-900 font-semibold test-base mx-2">
                    {formatPhoneNumber(data.phone)}
                  </span>
                </div>
              ) : (
                "Set # for location"
              )}
            </Popover>
          </div>
        )}
        {data.note && (
          <div>
            <Popover
              className="w-43"
              position="top"
              trigger={<Badge color="green">Note</Badge>}
              offset={[-12, 0]}
            >
              {data.note}
            </Popover>
          </div>
        )}
      </div>
    </div>
  );
};

export default LocationTableCell;
