import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import driverTable from "./driver.table.config";
import orgTable from "./org.table.config";
import TableLayout from "../../layouts/TableLayout/NewTableLayout";
import { useAuth } from "../../hooks/useAuth";
import {
  fetchDrivers,
  resetDrivers,
  selectAllDrivers,
} from "../../redux/reducers/drivers/driversSlice";
import DriverCreateModal from "./DriverCreateModal";
import OrgCreateModal from "../Organizations/OrgCreateModal";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import Dropdown from "../../shared/Dropdown";
import {
  fetchOrganizations,
  resetOrganizations,
} from "../../redux/reducers/organizations/organizationsSlice";
import { useGetDriversQuery } from "../../redux/services/drivers/driversApi";
import { usePrefetch } from "../../redux/api";

const Drivers = () => {
  const { user } = useAuth();
  const dispatch = useDispatch();

  const [modalVisible, setModalVisible] = useState(false);

  const [type, setType] = useState("Drivers");
  const isDrivers = type === "Drivers";

  const [{ pageIndex, pageSize }, setPagination] = useState({
    pageIndex: 0,
    pageSize: 20,
  });

  const { data, isLoading, isFetching } = useGetDriversQuery({
    page: pageIndex + 1,
    limit: pageSize,
  });

  const prefetchPage = usePrefetch("getDrivers");

  const prefetchNext = useCallback(() => {
    prefetchPage({
      page: data.nextPage,
      limit: pageSize,
    });
  }, [prefetchPage, data?.nextPage, pageSize]);

  useEffect(() => {
    if (data?.hasNext && data.nextPage <= data.totalPages) {
      prefetchNext();
    }
  }, [data, pageIndex, prefetchNext]);
  /////

  const drivers = useSelector(selectAllDrivers);
  const driversStatus = useSelector((state) => state.drivers.status);

  useEffect(() => {
    if (isDrivers && driversStatus === "idle") {
      dispatch(fetchDrivers({ parent: user.parentCompany, type: "driver" }));
    }
  }, [driversStatus, dispatch, user, type]);

  const organizations = useSelector((state) => state.organizations);
  const organizationsStatus = useSelector(
    (state) => state.organizations.status
  );

  const status = isDrivers ? driversStatus : organizationsStatus;

  const tableData = isDrivers ? data?.drivers : organizations.data;

  useEffect(() => {
    if (type === "Organizations" && organizationsStatus === "idle") {
      dispatch(fetchOrganizations({ parent: user.parentCompany }));
    }
  }, [organizationsStatus, dispatch, user, type]);

  useEffect(() => {
    return () => {
      dispatch(resetDrivers());
      dispatch(resetOrganizations());
    };
  }, []);

  const createPath = `/users/create`;

  function handleTypeChange(e) {
    setType(e.value);
    dispatch(resetDrivers());
  }

  const formTypeOptions = [
    {
      value: "Drivers",
      onClick: handleTypeChange,
      label: "Drivers",
    },
    {
      value: "Organizations",
      onClick: handleTypeChange,
      label: "Organizations",
    },
  ];

  const formTypeTrigger = (
    <div className="text-2xl font-bold flex items-center cursor-pointer ">
      {type}
      <ChevronDownIcon className="h-3 w-3 ml-1" />
    </div>
  );

  const actions = (
    <div>
      <Dropdown
        align="left"
        closeOnClick
        trigger={formTypeTrigger}
        data={formTypeOptions}
      />
    </div>
  );

  const colDefs = isDrivers
    ? driverTable.COLUMN_DEFINITIONS
    : orgTable.COLUMN_DEFINITIONS;
  const pageOptions = isDrivers
    ? driverTable.PAGE_SIZE_OPTIONS
    : orgTable.PAGE_SIZE_OPTIONS;

  return (
    <>
      <TableLayout
        mainAction={{ onClick: () => setModalVisible(true) }}
        id="drivers"
        title={actions}
        loading={isLoading || isFetching}
        columns={colDefs}
        data={tableData}
        pagination={data}
        pageIndex={pageIndex}
        pageSize={pageSize}
        onPaginationChange={setPagination}
        pageSizeOptions={pageOptions}
      />
      {isDrivers ? (
        <DriverCreateModal
          visible={modalVisible}
          setVisible={setModalVisible}
        />
      ) : (
        <OrgCreateModal visible={modalVisible} setVisible={setModalVisible} />
      )}
    </>
  );
};

export default Drivers;
