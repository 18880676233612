import { useMemo } from "react";
import { useDispatch } from "react-redux";
import Card from "../../../shared/Card";
import Table from "../../../shared/Table";
import { renderColumnDefs } from "../../../shared/Table/table.utils";
import {
  jobLegUpdated,
  jobUpdated,
  updateJob,
} from "../../../redux/reducers/jobs/jobSlice";
import { COLUMN_DEFINITIONS } from "./table.config";
import { useAuth } from "../../../hooks/useAuth";
import { updateLeg } from "../../../redux/reducers/legs/legSlice";
import { useUpdateManifestLegMutation } from "../../../redux/services/manifests/manifestsApi";
import { toast } from "react-toastify";

const JobLegs = ({ info }) => {
  const legs = info.legs?.map((item) => {
    return {
      ...item,
      // jobId: data._id,
    };
  });
  const { user } = useAuth();

  const [updateManifestLeg, { isLoading }] = useUpdateManifestLegMutation();

  async function handleUpdate(e, setLoading) {
    let useCustomManifestMileage = false;
    const data = {
      ...e.data,
      useCustomManifestMileage,
    };

    if (e.column.id === "route") {
      data.useCustomManifestMileage = true;
      data.customManifestMileage = e.value;
    }

    delete data.route;

    const updateData = {
      // parent: user.parentCompany,
      legId: e.row.original._id,
      id: info._id,
      data,
    };

    const res = await updateManifestLeg(updateData).unwrap();

    toast(res.message, {
      type: res.status,
      toastId: "updated-leg-" + res.data._id,
    });

    if (res.status === "success") {
      setTimeout(() => {
        setLoading(false);
      }, 500);
    }
  }

  if (!legs) return "Loading...";
  return (
    <div>
      <Table
        pageSize={100}
        data={legs}
        columns={COLUMN_DEFINITIONS}
        hideCheckbox
        updateData={handleUpdate}
      />
    </div>
  );
};

export default JobLegs;
