import { api } from "../../api";

const driversApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getDrivers: builder.query({
      query: ({ page = 1, limit = 20 }) =>
        `drivers?page=${page}&limit=${limit}&legacy=false`,
    }),
  }),
  overrideExisting: false,
});

export const { useGetDriversQuery } = driversApi;
