import { useRef, useState, useEffect } from "react";
import { Scheduler } from "../../shared";
import { SidebarContainer } from "../../components";
import { useGetDispatchListQuery } from "../../redux/services/dispatch/dispatchApi";
import { endOfDay, startOfDay } from "date-fns";

export const Dispatch = () => {
  const [selectedDate, setSelectedDate] = useState(new Date());

  function getStart() {
    return startOfDay(selectedDate);
  }
  function getEnd() {
    return endOfDay(selectedDate);
  }

  const { data: list } = useGetDispatchListQuery({
    startDate: getStart(),
    endDate: getEnd(),
  });

  const headerRef = useRef();
  const [headerContext, setHeaderContext] = useState();
  useEffect(() => {
    setHeaderContext(headerRef.current);
  }, [headerRef.current]);

  const renderHeader = () => {
    return <div ref={headerRef}></div>;
  };

  function handleSelectedDate(e) {
    setSelectedDate(e.value);
  }

  const fieldsData = {
    id: "_id",
    subject: { name: "driver" },
    startTime: { name: "appointmentDate" },
    endTime: { name: "RequiredDate" },
    recurrenceRule: { name: "ShipRegion" },
  };
  return (
    <SidebarContainer header={renderHeader} hideSidebar className="w-full">
      {({ parentHeight }) => {
        const offsetTop = headerContext?.offsetTop;
        const offsetHeight = headerContext?.offsetHeight;
        const h = `${parentHeight - offsetTop - offsetHeight}px`;
        const wrapperStyle = {
          height: `${parentHeight - offsetTop - offsetHeight}px`,
        };

        return (
          <div className="overflow-y-auto w-full ">
            <Scheduler
              selectedDate={selectedDate}
              setSelectedDate={handleSelectedDate}
              height={h}
              data={list}
              fields={fieldsData}
            />
          </div>
        );
      }}
    </SidebarContainer>
  );
};
